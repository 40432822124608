.App {
  text-align: center;
}

.App-header {
  background-color: #fff;
  color: #252525;
}

.App-header .App-background {
  background-image: url("./assets/headerbg.jpeg");
  /* Set a specific height */
  min-height: 500px; 

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.App-header .App-background-theme-color {
  min-height: 275px;
  background-color: var(--theme-color2);
}

.App-header .container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100vw;
  background-color: transparent;
  transition: all 250ms ease;
  box-shadow: var(--noshadow);
}

.App-header .container .App-sidebar .menuList {
  position: relative;
  display: flex;
  flex-direction: row;
  list-style: none;
  align-items: center;
  justify-content: space-around;
  width: 35vw;
  background-color: #fffa;
  padding: 1rem;
  margin: 1rem;
  border-radius: 1rem;
  font-size: large;
  font-weight: 600;
  box-shadow: var(--shadow);
  transition: all 250ms ease;
}

.App-header .container .App-sidebar .menuList li.extraContainer::after {
  position: absolute;
  content: "";
  width: 50%;
  background-color: transparent;
  height: 50%;
  top: 40px
}

.App-header .container .App-sidebar .menuList li.extraContainer:hover .extras {
  opacity: 1;
  pointer-events: all;
  transform: translateY(0px);
}

.App-header .container .App-sidebar .menuList li.extraContainer .extras {
  position: absolute;
  top: 60px;
  display: flex;
  flex-direction: column;
  text-align: start;
  justify-content: center;
  align-items: flex-start;
  background-color: #fffa;
  padding: 2rem;
  border-radius: 1rem;
  opacity: 0;
  pointer-events: none;
  transform: translateY(-10px);
  transition: all 250ms ease;
  box-shadow: var(--shadow);
}

.App-header .container .App-sidebar .menuList li.extraContainer .extras li {
  margin-bottom: 1rem;
}

.App-header .container .App-sidebar .menuList li {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-header .container .App-sidebar .menuList li svg {
  padding-right: 0.5rem;
}

.App-header .container.scroll .App-sidebar .menuList {
  background-color: transparent;
  box-shadow: var(--noshadow);
}

.App-header .container.scroll {
  background-color: #fffa;
  box-shadow: var(--shadow);
}

.App-header .container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(20px);
  opacity: 0;
  z-index: -1;
  transition: all 250ms ease;
}

.App-header .container.scroll .App-sidebar .menuList::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(20px);
  opacity: 0;
  z-index: -1;
  transition: all 250ms ease;
  border-radius: 1rem;
}

.App-header .container .App-sidebar .menuList::before, .extras::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 1rem;
  backdrop-filter: blur(20px);
  opacity: 1;
  z-index: -1;
  transition: all 250ms ease;
}

.App-header .container.scroll::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(20px);
  opacity: 1;
  z-index: -1;
  transition: all 250ms ease;
}

.App-header .container .App-logo img{
  width: 300px;
  transition: all 250ms ease;
  cursor: pointer;
}

.App-header .container.scroll .App-logo img {
  width: 180px;
}

.App-header .container .hamburger-menu {
  display: none;
}

.App-header .App-pageTitle {
  position: relative;
  z-index: 0;
  margin: 0 auto;
  margin-top: -50px;
  font-size: xx-large;
  background-color: var(--theme-color);
  width: 50vw;
  color: #fff;
  padding: 1rem;
  font-weight: bold;
  border-radius: 1rem;
  box-shadow: var(--shadow);
}

.App-pageTitle-theme-color {
  position: relative;
  z-index: 0;
  margin: 0 auto;
  margin-top: -8rem;
  padding-bottom: 3rem!important;
  font-size: 3rem;
  color: #fff;
  padding: 1rem;
  font-weight: bold;
}

.App-header .container .App-sidebar ul .closeButton, .App-header .container.scroll .App-sidebar ul .closeButton {
  display: none;
  cursor: pointer;
}

.App-footer {
  background-color: #eee;
  padding: 1rem 0;
  margin-top: 1rem;
  color: #555555;
}

.cards .App-card {
  background-color: #eee;
  padding: 1rem;
  width: 25vw;
  border-radius: 1rem;
  box-shadow: var(--shadow);
}

.cards .App-card svg, .App-card .title {
  color: var(--theme-color2);
  font-size: x-large;
}

.cards .App-card svg {
  font-size: xx-large;
}

.cards .App-card .title {
  padding-bottom: 1rem;
}

.cards {
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

h2 {
  color: var(--theme-color);
  font-size: 3rem;
}

h3 {
  color: var(--theme-color2);
  font-size: 2rem;
}

.App-data {
  text-align: start;
  padding: 1rem 3rem;
}

.App-data li {
  padding: 1rem;
}

.App-subscreen {
  display: flex;
  flex-direction: column;
}

.subscreen-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eee;
}

.subscreen-menu ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
  width: 100vw;
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
}

.subscreen-menu li {
  padding: 2rem;
  cursor: pointer;
  transition: all 250ms ease;
}

.subscreen-menu li.active, .subscreen-menu li:hover {
  background-color: #ccc;
}

.subscreen {
  text-align: start;
  padding: 1rem 12rem;
}

.subscreen h2 {
  font-size: 2.5rem;
}

.subscreen h3 {
  font-size: 1.5rem;
}

.subscreen li {
  padding: 0.5rem 0;
}

.comparison-images {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 1rem;
}

.comparison-images img {
  border-radius: 1rem;
}

.comparison-images .comparison {
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1rem;
  width: 450px;
}

.subscreen h4 {
  margin: 0;
  padding: 0;
  color: var(--theme-color);
}

.subscreen-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.subscreen-column {
  display: flex;
  flex-direction: column;
}

.subscreen-column:first-child {
  width: 70%;
}

.subscreen img{
  width: 280px;
  margin: 1rem 0;
  border-radius: 1rem;
}

.subscreen .chart {
  width: 500px;
}

.subscreen hr {
  border-color: #e2e2e24b;
  margin: 0 12rem;
}

@media screen and (max-width: 1024px) {
  .App-header .container .App-sidebar .menuList li.extraContainer::after {
    content: none;
  }

  .App-header .container .App-sidebar .menuList li.extraContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .App-header .container .App-sidebar .menuList li.extraContainer .extras {
    opacity: 1;
    position: relative;
    background-color: transparent;
    box-shadow: none;
    transform: none;
    top: unset;
    pointer-events: all;
    padding-top: 1rem;
    padding-bottom: 0;
  }

  .App-header .container .App-sidebar .menuList li.extraContainer .extras li:last-child {
    margin-bottom: 0;
  }

  .App-header .container .App-sidebar .menuList li.extraContainer .extras::before {
    content: none;
  }

  .subscreen-column:first-child {
    width: 100%;
  }

  .subscreen hr {
    border-color: #e2e2e24b;
    margin: 0 8rem;
  }

  .subscreen {
    padding: 1rem 8rem;
  }

  .cards {
    flex-direction: column;
  }

  .cards .App-card {
    margin-bottom: 2rem;
    width: 80vw;
  }

  .App-header .App-pageTitle {
    width: 80vw;
  }

  .App-header .container {
    flex-direction: row;
    align-items: center;
  }

  .App-header .container menu {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    margin: 0;
    padding: 0;
    display: none;
  }

  .App-header .container.active menu {
    display: block;
  }

  .App-header .container .App-sidebar .menuList, .App-header .container.scroll .App-sidebar .menuList {
    flex-direction: column;
    border-radius: 0;
    height: 100vh;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    text-align: start;
    align-items: flex-start;
    font-size: x-large;
    width: 100vw;
    animation: 500ms ease 0s 1 reveal;
    background-color: #fffd;
  }

  .closeButton svg {
    height: 50px;
    margin-top: 1.2rem;
    margin-left: 1rem;
  }

  .App-header .container .App-sidebar .menuList .closeButton, .App-header .container.scroll .App-sidebar .menuList .closeButton {
    display: block;
    border-bottom: 1px solid #25252550;
    width: 100vw;
    margin-bottom: 1rem;
    padding-bottom: 1.2rem;
  }

  .App-header .container .App-sidebar .menuList li, .App-header .container.scroll .App-sidebar .menuList li {
    padding: 1rem 2rem;
    animation: 500ms ease 0s 1 slide;
  }
  
  .App-header .container.scroll .App-sidebar .menuList::before, .App-header .container .App-sidebar .menuList::before  {
    opacity: 1;
    border-radius: 0;
  }

  .App-header .container .hamburger-menu {
    display: flex;
    flex-direction: column;
    width: 30px;
    padding: 1rem;
    cursor: pointer;
  }

  .App-header .container .hamburger-menu span {
    height: 3px;
    background-color: #252525DD;
    margin-bottom: 5px;
    border-radius: 99rem;
    transition: all 250ms ease;
  }

  .App-header .container .hamburger-menu.theme-color span {
    background-color: #fff;
  }

  .App-header .container .hamburger-menu.theme-color.scroll span {
    background-color: #252525DD;
  }
}

@media screen and (max-width: 756px) {
  .subscreen {
    text-align: start;
    padding: 1rem 4rem;
  }

  .subscreen hr {
    border-color: #e2e2e24b;
    margin: 0 4rem;
  }

  .App-pageTitle-theme-color {
    font-size: 2rem;
  }

  .subscreen-menu ul {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    font-size: 1.2rem;
  }
  
  .subscreen-menu li {
    padding: 1rem;
    margin: 1rem 0;
    cursor: pointer;
    transition: all 250ms ease;
    border-radius: 1rem;
  }
  
  .subscreen-menu li.active, .subscreen-menu li:hover {
    background-color: #ccc;
  }
}

@media screen and (max-width: 450px) {
  .subscreen {
    padding: 1rem 4rem;
  }

  .App-header .container .App-logo img {
    width: 220px;
  }
}

@keyframes reveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slide {
  0% {
      opacity: 0;
      transform: translateX(-100px);
  }
  100% {
      opacity: 1;
      transform: translateX(0px);
  }
}
