body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: var(--theme-color2);
}

:root {
  --theme-color: rgb(47, 101, 88);
  --theme-color2: rgb(79, 170, 148);
  --shadow: 0px 2.8px 2.2px rgba(0, 0, 0, 0.02),
        0px 6.7px 5.3px rgba(0, 0, 0, 0.028),
        0px 12.5px 10px rgba(0, 0, 0, 0.035),
        0px 22.3px 17.9px rgba(0, 0, 0, 0.042),
        0px 41.8px 33.4px rgba(0, 0, 0, 0.05),
        0px 100px 80px rgba(0, 0, 0, 0.07);
  --noshadow: 0px 0px 0px #0000;
}

.react-tabs__tab-list {
  border-color: #f0f0f0!important;
}

.react-tabs__tab {
  border-color: #f0f0f0!important;
  padding: 12px!important;
  position: unset!important;
  background-color: #EBEBEB;
  transition: all 250ms ease;
}

.react-tabs__tab:hover {
  background-color: #f8f8f8!important;
}

.react-tabs__tab--selected {
  background-color: #f8f8f8!important;
}

.divider {
  border-bottom: 1px solid #f0f0f0;
  margin: 1rem;
}

.react-tabs__tab:focus:after {
  content: none!important;
}

@media screen and (max-width: 1024px) {
  .react-tabs__tab-list {
    display: flex;
    flex-direction: column;
    border-radius: 5px!important;
  }
  .react-tabs__tab--selected {
    border: 1px solid #f0f0f0!important;
    border-radius: 5px!important;
  }
}